<template lang="pug">
  .fixed.inset-0.z-10.table.w-full.h-full.bg-windowShadow.font-SourceSansPro(
    style=' z-index: 99'
  )
    .table-cell.align-middle
      .mx-auto.shadow.p-6.bg-white.flex.flex-col(style='width: 460px; height:811px')
        .flex.w-full.items-center
          p.text-darkblue.text-xl.font-bold.whitespace-no-wrap.font-SourceSansPro(
            style='font-size: 28px'
          ) Тариф
          i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
            @click='$emit("close")',
            style='justify-self: flex-end; margin-left: auto'
          ) 
        .flex.flex-col.text-center.mt-4
          p.header-text.tariff-date(v-if="activeCompany.active_company.billing.company_status === 'TRIAL'") Ваш пробный период истекает через {{ days }} дней ({{ date}})
          p.header-text-info Использование системы мониторинга SKIF.PRO является платным. Вы можете увеличить максимальное количество объектов  доступных вашей компании (но это приведёт к удорожанию тарифа) . 
          

        .flex.flex-col.mb-2
          .flex.mt-4.justify-between.w-full
            p.mr-4.text-darkblue.font-bold.font-SourceSansPro(
              style='font-weight: 600; width:40%; line-height:120%'
            ) {{ $t("tariff.choose-units") }}
            el-input-number.input-number(
              v-model='objectNumbers',
              size='small',
              :min='countUnits',
              :max='1000'
            )
          p.accept-info Если после нажатия на кнопку "Согласен" вы захотите уменьшить количество объектов в тарифе, Вам придется обратится в поддержку.
          .mt-2.tariff-card(v-if='activeCompany.active_company.billing.tariff.type==="charge_all_units"')
            p.mr-4.text-darkblue.font-bold.font-SourceSansPro.m-2  Тариф
            p.information-text.mx-2  {{ $t("tariff.info-one") }}
            p.information-text.mx-2 {{ amount + ' ' + activeCompany.active_company.billing.currency.display_name }} {{ $t("tariff.info-two--part-one") }} {{ days }} {{ $t("tariff.info-two--part-two") }}
            .dashed-border.mt-4
            .p-2.flex.w-full.justify-between.flex-col
              p.mr-4.text-darkblue.font-bold.whitespace-no-wrap.font-SourceSansPro(
                style='font-weight: 600'
              ) {{ $t("tariff.tariffprice") }}
              .flex.justify-between.align-center
                p.header-text {{ this.objectNumbers * amount }} {{  activeCompany.active_company.billing.currency.code==='RUB' ? $t("tariff.rouble") : activeCompany.active_company.billing.currency.display_name  }}
                p.to-pay {{ $t("tariff.payableto") + ' ' + date }}
          .mt-2.tariff-card(v-else)
            p.mr-4.text-darkblue.font-bold.font-SourceSansPro.m-2  Тариф
            p.information-text.mx-2  Стоимость за 1 объект присылавший данные {{ activeCompany.active_company.billing.tariff.price + ' ' + activeCompany.active_company.billing.currency.display_name }} .
            p.information-text.mx-2 Период оплаты {{ activeCompany.active_company.billing.tariff.length }} календарных дней.
            p.information-text.mx-2 Максимальное количество объектов - {{ activeCompany.active_company.billing.tariff.units }}.
            .dashed-border.mt-4
            .p-2.flex.w-full.justify-between.flex-col
              p.mr-4.text-darkblue.font-bold.whitespace-no-wrap.font-SourceSansPro(
                style='font-weight: 600'
              ) Максимальная стоимость тарифа:
              p.info-tariff Стоимость платежа перерассчитывается ежедневно до момента оплаты.
              .flex.justify-between.align-center
                p.header-text ~~~~~  {{  activeCompany.active_company.billing.currency.display_name   }}.
                p.to-pay {{ $t("tariff.payableto") + ' ' + date }}
        .flex.justify-end.h-full(style='align-items: flex-end;')
          .cont.w-full.flex(style=' border-top: 1px solid #CAD6DF')
            skif-button.mt-2.mr-4(style=' width:200px' variant='normal', @click='$emit("close")') {{ $t("btn.cancel") }}
            skif-button.mt-2(style=' width:200px' @click='unblock', v-if='isblocked') {{ $t("tariff.accept") }}
            skif-button.mt-2(style=' width:200px' @click='accept', v-else) {{ $t("tariff.accept") }}
        p.mt-4.information-text.ml-4.text-center {{ $t("tariff.txt-six") + $t("tariff.txt-seven")  }}  <span @click="getOferta(me.active_company_id)" class='payement-date cursor-pointer'> {{ $t("tariff.offers") }} </span>
      
</template>
<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

export default {
  props: ['isblocked'],
  data() {
    return {
      objectNumbers: 0,
      date: '',
      currentAmount: ''
    }
  },

  computed: {
    ...mapState('login', {
      activeCompany: (state) => state.me
    }),
    ...mapState('units', {
      oldunits: (state) => state.units,
      units: (state) => state.allUnits
    }),
    ...mapState('billing', {
      newOrder: (state) => state.newOrder
    }),
    ...mapState({
      me: (state) => state.login.me
    }),

    amount() {
      return this.me.active_company.billing.tariff.price || 0
    },

    countUnits() {
      return this.me.active_company.billing.tariff.units || 0
    },

    days() {
      return this.me.active_company.billing.tariff.length || 0
    }
  },

  methods: {
    ...mapActions({
      getOferta: 'login/GET_TERMS',
      newBilling: 'login/NEWBILLING'
    }),
    accept() {
      if (this.units.length > this.objectNumbers) {
        console.log('Нет возвожности выполнить действие')
      }
      const objNum = this.objectNumbers * this.amount
      this.$emit('unblock', { amount: objNum })
      this.$emit('close')
    },
    unblock() {
      const objNum = this.objectNumbers * this.amount
      this.$emit('unblock', { amount: objNum })
      this.$emit('close')
    }
  },
  created() {
    this.date = moment().add(this.days, 'days').format('DD-MM-YYYY')
    this.objectNumbers = this.countUnits
    this.currentAmount = this.units.length
  }
}
</script>
<style>
.info-tariff {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  margin-bottom: 6px;
  /* identical to box height, or 17px */

  /* grey (tone 3) */

  color: #828d9b;
}
.tariff-card {
  border: 1px solid #cdd4dd;
  border-radius: 3px;
}
.dashed-border {
  border-bottom: 1px dashed #cdd4dd;
}
.price {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */

  text-align: center;

  color: #38495a;
}
.to-pay {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  text-align: right;

  color: #38495a;
}

.tariff-date {
  color: #20579a;
  margin-bottom: 16px;
  background: #dbebff;
  border-radius: 3px;
  padding: 12px 25px;
  text-align: center;
}
.header-text-info {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
}
.payement-date {
  color: #5477a9;
}
.cont p {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #333333;
}
.information-text {
  font-size: 14px;
  color: #677785;
}
.input-number {
  align-self: flex-end;
}

.input-number input {
  height: 32px !important;
}
.input-number .el-input-number__decrease,
.input-number .el-input-number__increase {
  background: white;
}
.accept-info {
  color: #bd6039;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.4px;
  margin-top: 12px;
  padding: 10px 20px;
  background-color: #fff1e9;
  border-radius: 3px;
}

.header-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  text-align: center;

  /* text main */
}
</style>
